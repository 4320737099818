body {
    -ms-overflow-style: scrollbar;
}

html, body {
    width: 100%;
    height: 100%;
}

.hidden {
    visibility: hidden;
    height: 600px;
}

.visible {
    height: 600px;
    visibility: visible;
}

.forecastChart{
    height: 600px;
}

.btn:focus {
    outline: none;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
}

.button, .btn-normal, .btn-normal:focus, .btn-normal:hover, .btn-normal:active {
    outline: none;
    background-image: none;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 4px;
    /* border-width: 10px; */
    font: inherit;
    background-color: #11aa00;
    border: 0px;
    color: white;
}

.btn-alert, .btn-alert:focus, .btn-alert:hover, .btn-alert:active {
    outline: none;
    background-image: none;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 4px;
    /* border-width: 10px; */
    font: inherit;
    background-color: red; /*    #66cc00;*/
    border: 0px;
    color: white;
}

div#root{
    height: 100%;
}

.grid-wrapper{
    height: 100%;
}

div.defaultDiv{
}

.header {
    /*display: flex;
    flex-direction: row;*/
    color: #fff;
    /* position: fixed; */
    padding: 20px;
    height: 60px;
    background-color: #66cc00;
    /*/* width: 80%; */
    margin-right: -20px;
    margin-left: -20px;
}

.datePicker {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.mainContent {
    overflow: visible;
    height: 100%;
}

/* ensures that popover switch does not go off screen */
.popover-switch{
    margin: 10px;
}

.inRangeDatePicker {
    background-color: #91cc7b !important;
}

.selectedDayDatePicker {
    background-color: #66cc00 !important;
}

.subMenuItemExpanded{
    padding-left: 20px;
}

.hide {
    display: none;
}

/* ensure divs have spacing on their left side */
/* - used with flex view divs aligned next to each other (e.g.
    header buttons)
*/
.divSpacing{
    margin-left: 10px;
}

/* style for main content of side nav */
.sideNavMain{
    position: relative;
    height: 90%;
}

.sideNavMain > nav {
    background-color: #66cc00 !important;
}

.sideNavMain div, .sideNavMain svg{
    color: white!important;
}

.sideNavMain div[role=menu]{
    background-color: #66cc00 !important;
}


.sideNavMain div[role=menuitem]:hover{
    background-color: #11AA00 !important;
}

/* style for main content of side nav */
.sideNav{
    position: relative;
    height: 90%;
}

/* style for side nav footer */
.sideNavFooter {
    position: relative;
    width: 100%;
    height: 10%;
    minHeight: 100px;
    maxHeight: 100px;
    width: 100%;
    background-color: #11AA00;
    color: #fff;
    text-align: center;
}

/* style for side nav */
.sideNavContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all .15s;
}

/* style for invalid input in div*/
input.invalidInput {
    color: darkred;
}

input.editableField, input.invalidInput{
    border: 0px;

}

.optionGroup {
    margin-bottom: 5px;
    margin-top: 5px;
}

/* Table Stylings */
.number-cell {
    text-align: right;
}

.ag-font-style {
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
}

.ag-cell-selected {
    background-color: #aaffaa;
}

.hiddenInput{
    position: relative;
    left: -500px;
    top: 0;
    width: 1px;
    height: 1px;
}

div.labelDiv {
    margin-right: 10px;
}

.DateInput {
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    padding: 6px;
    width: 605px;
}